<template>
  <div class="pageManagePage">
    <!-- 顶部按钮 -->
    <div class="head">
      <el-button type="primary" size="medium" @click="add">添加页面</el-button>
    </div>
    <!-- 表格输入 -->
    <el-table :data="tableData" border>
      <el-table-column fixed align="center" prop="ID" label="序号">
      </el-table-column>
      <el-table-column align="center" prop="Name" label="路由名称" width="150">
      </el-table-column>
      <el-table-column align="center" prop="Path" label="路由地址" width="150">
      </el-table-column>
      <el-table-column align="center" prop="fullPath" label="完整路由地址" width="350">
      </el-table-column>
      <el-table-column align="center" prop="Redirect" label="重定向地址" width="300">
      </el-table-column>
      <el-table-column align="center" prop="ImageUrl" label="图标" width="150">
      </el-table-column>
      <el-table-column align="center" prop="SortIndex" label="排序">
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="200">
        <template slot-scope="scope">
          <!-- <el-button @click="detail(scope.row)" type="primary" size="small">详情</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <!-- 新增dialog -->
    <el-dialog title="非开发人员请勿使用此功能" :visible.sync="flag.addDialog" width="700px" center>
      <span slot="footer" class="dialog-footer">
        <!-- 表单 -->
        <p style='margin-bottom: 30px;'>添加路由后需在前端项目文件夹内添加对应的.vue文件</p>
        <el-form class="addForm" :model="addForm" :rules="addRule" ref="addForm" label-width="150px">
          <el-form-item label="请选择页面归属角色" prop="remark">
            <el-select v-model="addForm.parentId" placeholder="请选择页面归属角色">
              <el-option label="平台" value="平台"></el-option>
              <el-option label="货主" value="货主"></el-option>
              <el-option label="服务商" value="服务商"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择父级路由" prop="routeLevel">
            <!-- <el-select v-model="addForm.routeLevel" placeholder="请选择路由级别">
              <el-option label="二级路由" value="2"></el-option>
              <el-option label="三级路由" value="3"></el-option>
              <el-option label="四级路由" value="4"></el-option>
            </el-select> -->
            <el-cascader :options="allRouterList" :show-all-levels="false"></el-cascader>
          </el-form-item>
          <el-form-item label="路由名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="路由地址" prop="path">
            <el-input v-model="addForm.path"></el-input>
          </el-form-item>
          <el-form-item label="完整路由地址" prop="fullPath">
            <el-input v-model="addForm.fullPath"></el-input>
          </el-form-item>
          <el-form-item label="重定向地址" prop="redirect">
            <el-input v-model="addForm.redirect"></el-input>
          </el-form-item>
          <el-form-item label="项目内页面存放路径" prop="navigateUrl">
            <el-input v-model="addForm.navigateUrl"></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="imageUrl">
            <el-input v-model="addForm.imageUrl"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortIndex">
            <el-input v-model="addForm.sortIndex"></el-input>
          </el-form-item>
        </el-form>
        <div class="btns">
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _getRouterList } from '@/utils/storage'
import { getMenus } from '@/api/power/systemStatus'
export default {
  data() {
    return {
      flag: {
        addDialog: false
      },
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      addForm: {
        name: '',
        path: '',
        fullPath: '',
        redirect: '',
        sortIndex: '',
        remark: '',
        navigateUrl: ''
      },
      addRule: {
        name: [{ required: true, message: '请输入路由名称', trigger: 'blur' }],
        path: [{ required: true, message: '请输入路由地址', trigger: 'blur' }],
        fullPath: [{ required: true, message: '请输入完整路由地址', trigger: 'blur' }],
        remark: [{ required: true, message: '请选择路由级别', trigger: 'blur' }],
        navigateUrl: [{ required: true, message: '请输入项目内页面存放路径', trigger: 'blur' }],
        sortIndex: [{ required: true, message: '请输入排序', trigger: 'blur' }],
      },
      tableData: [],
      allRouterList: []
    }
  },
  methods: {
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.getMenus()
    },
    //获取路由列表
    getMenus() {
      let params = {
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize
      }
      getMenus({ Json: JSON.stringify(params) }).then(res => {
        this.tableData = res.menus.Item1
        this.pagination.total = res.menus.Item2
      })
    },
    //新增路由
    add() {
      this.flag.addDialog = true
    },
    //确认
    confirm() {
      let params = {
        name: this.addForm.name,
        path: this.addForm.path,
        fullPath: this.addForm.fullPath,
        redirect: this.addForm.redirect,
        remark: this.addForm.remark,
        navigateUrl: this.addForm.navigateUrl,
        sortIndex: this.addForm.sortIndex,
      }
    },
    //初始化路由列表
    initList() {
      //获取所有的路由拆分出一二三级
      getMenus({
        Json: JSON.stringify({
          PageIndex: 1,
          PageSize: 5000
        })
      }).then(res => {
        let twoRouterList = []
        let threeRouterList = []
        res.menus.Item1.forEach(item => {
          if (item.RouteLevel == 2) {
            item.label = item.Name
            item.value = item.ID
            twoRouterList.push(item)
          }
          if (item.RouteLevel == 3) {
            item.label = item.Name
            item.value = item.ID
            threeRouterList.push(item)
          }
        })
        this.allRouterList = [
          { value: 2, label: '二级路由', children: twoRouterList },
          { value: 3, label: '三级路由', children: threeRouterList },
        ]
      })
    }
  },
  created() {
    this.getMenus()
    this.initList()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.pageManagePage {
  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 30px;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 600px;
    }
    .el-select,
    .el-cascader {
      width: 450px;
    }
  }
  .btns {
    display: flex;
    justify-content: center;
  }
}
</style>